import { node } from "../../Axios";
import {
  getSession,
  removeSession,
  setAttesaNuovo,
  setSession,
} from "../../utils/session";
import { set_coperti, set_ordine, set_totale_generale } from "./ordini";
import { setErrore } from "./useform";

export const DESTROY_SESSION = "DESTROY_SESSION";

export const IDRUOLO = "IDRUOLO";
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const GETMENU_START = "GETMENU_START";
export const GETMENU_SUCCESS = "GETMENU_SUCCESS";
export const GETMENU_FAIL = "GETMENU_FAIL";
export const LOGIN_CHECK = "LOGIN_CHECK";
export const ATTESANUOVO = "ATTESANUOVO";
export const GET_CATEGORIE_START = "GET_CATEGORIE_START";
export const GET_CATEGORIE_SUCCESS = "GET_CATEGORIE_SUCCESS";
export const GET_CATEGORIE_FAIL = "GET_CATEGORIE_FAIL";
export const LOADING = "LOADING";

export const logini = (email, password) => {
  return async (dispatch) => {
    await dispatch(login_start());
    try {
      const res = await node.post("/api/login/login", {
        email: email,
        password: password,
        id_appcode: "92258bb8-9e51-4bc3-839d-6429aba8f037",
      });
      await dispatch(login_success(res));

      if (res.data.ruoliutente.length > 1) {
        return true;
      } else if (res.data.ruoliutente.length == 1) {
        await dispatch(salvaRuoloScelto(res.data.id_ruolo));
        
        await dispatch(
          getmenui(
            res.data.id_persona,
            res.data.id_appcode,
            res.data.ruoliutente[0].id_ruolo
          )
        );
       
      } else {
        await dispatch(setErrore("Utente senza ruoli"));
      }
    } catch (error) {
      dispatch(login_fail(error));
      if (error.response.status == "404") {
        await dispatch(setErrore(error.response.data.messaggio));
      }
      if (error.response.status == "422") {
        await dispatch(setErrore(error.response.data.messaggio));
      }
    }
  };
};

export const getmenui = (id_persona, appcode, value) => {
  return async (dispatch) => {
    await dispatch(getmenu_start());
    await dispatch(salvaRuoloScelto(value));

    try {
      const res = await node.post("/api/login/getmenu", {
        data: {
          id_persona: id_persona,
          id_appcode: appcode,
          id_ruolo: value,
        },
      });
      await setSession(res,value);
     
      await dispatch(get_categorie());

     // await dispatch(attesanuovo());

      await dispatch(getmenu_success(res));
    } catch (error) {
      await dispatch(getmenu_fail());
    }
  };
};

export const login_start = () => {
  return {
    type: LOGIN_START,
  };
};
export const login_success = (res) => {
  return {
    type: LOGIN_SUCCESS,
    res,
  };
};
export const login_fail = () => {
  return {
    type: LOGIN_FAIL,
  };
};

export const getmenu_start = () => {
  return {
    type: GETMENU_START,
  };
};
export const getmenu_success = (res) => {
  return {
    type: GETMENU_SUCCESS,
    res,
  };
};
export const getmenu_fail = () => {
  return {
    type: GETMENU_FAIL,
  };
};

export const salvaRuoloScelto = (id_ruolo) => {
  return {
    type: IDRUOLO,
    id_ruolo,
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      const sessione = await getSession();
      await removeSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };

      const bodyParameters = {};
      const res = await node.post("/api/login/logout", bodyParameters, config);
    } catch (error) {
      dispatch(login_fail(error));
    }
    await dispatch(onClear());
  };
};
export const logout_massivo = () => {
  return async (dispatch) => {
    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };

      const bodyParameters = {};
      const res = await node.post("/api/login/logoutmassivo", bodyParameters, config);
    } catch (error) {
      dispatch(login_fail(error));
    }
   
  };
};
export const logincheck = () => {
  return async (dispatch) => {
    dispatch(login_start());
      const sessione = await getSession();
      if (!sessione) {
        dispatch(login_fail());
        dispatch(logout());
      }
      await dispatch({ type: LOGIN_CHECK, sessione });
      try {
        const sessione = await getSession();
        const config = {
          headers: { Authorization: `Bearer ${sessione.token}` },
        };

        const bodyParameters = {};
        const res = await node.post(
          "/api/login/checkautorization",
          bodyParameters,
          config
        );
        dispatch(login_success(res));
        dispatch(set_ordine(-1));

      } catch (error) {
        if (error.response.status == "401") {
          dispatch(logout());
        }
      }
  };
};
export const attesanuovo = () => {
  return async (dispatch) => {
    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };

      const bodyParameters = {};
      const res = await node.post(
        "/api/login/attesanuovo",
        bodyParameters,
        config
      );

      if (res.data.tavoloaperto.length > 0) {
        await dispatch({ type: ATTESANUOVO, attesanuovo: true });
        await dispatch(
          set_coperti(res.data.tavoloaperto[0].ordini_tavolos[0].nume_persone)
        );
        await dispatch(
          set_totale_generale(res.data.tavoloaperto[0].totale)
        );
        await setAttesaNuovo(true);
      } else {
        await dispatch({ type: ATTESANUOVO, attesanuovo: false });
        await setAttesaNuovo(false);
      }
      // await dispatch(login_success(res));
    } catch (error) {
      dispatch(logout());
      dispatch(login_fail(error));
      return;
    }
  };
};

export const get_categorie = () => {
  return async (dispatch) => {
    await dispatch(get_categorie_start());
    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };

      const bodyParameters = {};
      const res = await node.post(
        "/api/categorie/getcategorie",
        bodyParameters,
        config
      );
      await dispatch(get_categorie_success(res));
    } catch (error) {
      await dispatch(get_categorie_fail());
      dispatch(logout())
    }
  };
};
export const get_categorie_start = () => {
  return {
    type: GET_CATEGORIE_START,
  };
};
export const get_categorie_success = (res) => {
  return {
    type: GET_CATEGORIE_SUCCESS,
    res,
  };
};
export const get_categorie_fail = () => {
  return {
    type: GET_CATEGORIE_FAIL,
  };
};

export const loading_login = (value) => {
  return {
    type: LOADING,
    value
  };
};
export const onClear = () => {
  return { type: DESTROY_SESSION };
};
