import React from "react";
import "../style/Cards.css";

const Cards = (props) => {
  let prezzo =
    props.card.prezzo_offerte != 0.0 && props.card.prezzo_offerte !== undefined
      ? Number(props.card.prezzo_offerte)
      : Number(props.card.prezzo);
  return (
    <div className="card">
      <a
        href="#fakeLink"
        onClick={() =>
          props.toggleModal(
            props.card,
            props.onqty,
            props.id_prodotto_interno,
            prezzo
          )
        }
      >
        <div className="titolo">{props.card.nome}</div>
        <img className="image" src={props.card.path} alt="BigCo Inc. logo" />
      </a>
      <div className="conenitore-info">
        <div className="prezzo"> Prezzo {Number(prezzo).toFixed(2)} €</div>
        <hr></hr>
        {props.isLogin ? (
          <div className="pulsanti">
            <div className="dec">
              <a
                href="#fakeLink"
                onClick={() =>
                  props.onDecrement(
                    props.card,
                    props.onqty,
                    props.id_prodotto_interno,
                    prezzo
                  )
                }
                className="btn purple"
              >
                -
              </a>
            </div>
            <div className="qty">{props.onqty}</div>

            <div className="inc">
              <a
                href="#fakeLink"
                onClick={() =>
                  props.onIncrement(
                    props.card,
                    props.onqty,
                    props.id_prodotto_interno,
                    prezzo
                  )
                }
                className="btn purple"
              >
                +
              </a>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Cards;
