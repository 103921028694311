import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import "../style/Navbar.css";

export default function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <nav>
      {(toggleMenu || screenWidth > 500) && (
        <ul className="list">
         {/*  <li className="items">
            <NavLink
              to="/"
              style={({ isActive }) => ({
                color: isActive ? "#fff" : "#545e6f",
                background: isActive ? "#7600dc" : null,
              })}
            >
              Home
            </NavLink>
          </li> */}
          <li className="items">
            {" "}
            <NavLink
              to="/"
              style={({ isActive }) => ({
                color: isActive ? "#fff" : "#545e6f",
                background: isActive ? "#7600dc" : null,
              })}
            >
              Menu
            </NavLink>
          </li>
        {/*   <li className="items">
            <NavLink
              to="/asdfa"
              style={({ isActive }) => ({
                color: isActive ? "#fff" : "#545e6f",
                background: isActive ? "#7600dc" : null,
              })}
            >
              Blog
            </NavLink>
          </li>
          <li className="items">
            <NavLink
              to="/asdsfa"
              style={({ isActive }) => ({
                color: isActive ? "#fff" : "#545e6f",
                background: isActive ? "#7600dc" : null,
              })}
            >
              Contatti
            </NavLink>
          </li>
          <li className="items">
            <NavLink
              to="/asdffa"
              style={({ isActive }) => ({
                color: isActive ? "#fff" : "#545e6f",
                background: isActive ? "#7600dc" : null,
              })}
            >
              Login
            </NavLink>
          </li> */}
        </ul>
      )}

      <button onClick={toggleNav} className="btnham">
        BTN
      </button>
    </nav>
  );
}
