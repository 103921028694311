let SESSIONE = {
  isLogin: false,
  token: null,
  menu: [],
  id_ruolo:""
};
let ATTESANUOVO = false;
let CARRELLO = [];
let CATEGORIE = [];

export const inizializzastorage = async () => {
  await localStorage.setItem("CARRELLO", JSON.stringify(CARRELLO));
  await localStorage.setItem("CATEGORIE", JSON.stringify(CATEGORIE));

};
export const setSession = async (res,value=null) => {
  SESSIONE.isLogin = true;
  SESSIONE.token = res.data.token;
  SESSIONE.menu = res.data.menu;
  SESSIONE.ruolo = value;

  await localStorage.setItem("SESSIONE", JSON.stringify(SESSIONE));
};
export const getSession = async () => {
  return JSON.parse(localStorage.getItem("SESSIONE"));
};

export const setAttesaNuovo = async (value) => {
  ATTESANUOVO = value;
  await localStorage.setItem("ATTESANUOVO", ATTESANUOVO);
};

export const getAttesaNuovo = async () => {
  return JSON.parse(localStorage.getItem("ATTESANUOVO"));
};

export const removeSession = async () => {
  await localStorage.removeItem("SESSIONE");
  await localStorage.removeItem("ATTESANUOVO");
  await localStorage.removeItem("CARRELLO");
  await localStorage.removeItem("CATEGORIE");
};
export const setCarrello = async (value) => {
  CARRELLO = value;
  await localStorage.setItem("CARRELLO", JSON.stringify(CARRELLO));
};

export const removecarrello = async () => {
  await localStorage.removeItem("CARRELLO");
};

export const setCategorie = async (value) => {
  CARRELLO = value;
  await localStorage.setItem("CATEGORIE", JSON.stringify(CATEGORIE));
};

export const removecategorie = async () => {
  await localStorage.removeItem("CATEGORIE");
};
