import React from "react";
import "../style/Layout.css";

import { Outlet } from "react-router-dom";

import Navbar from "../components/Navbar";

const Layout = (props) => {
  //const loading = useSelector((state) => state.login.loading);

  return (
    <div className="container-layout">
      <div className="header">
        <>
          <Navbar />
        </>
      </div>
      <div className="slider">
       slider
      </div>
      <div className="corpo">
        <Outlet />
      </div>
    </div>
  );
};
export default Layout;
